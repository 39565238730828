import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';

const App = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
      axios.get('https://test-react.boopler.com/home.php')
         .then(function(res) {
            console.log(res);
         });
   }, []);

   return (
      <Router>
         <div>
            <nav>
               <NavLink to="/" activeClassName="active">Home</NavLink>
               <NavLink to="/profile" activeClassName="active">Sobre</NavLink>
            </nav>
            <Routes>
               <Route exact path="/" element={<Home />} />
               <Route path="/profile" element={<Profile />} />
            </Routes>
         </div>
      </Router>
   );
};

export default App;
