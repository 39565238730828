// Profile.js
import React from 'react';

const Profile = () => {
  return (
    <div>
      <h2>Profile</h2>
      {/* Conteúdo da página de perfil */}
    </div>
  );
};

export default Profile;
